import axios from "axios";
import { MDBCol, MDBInput, MDBRow, MDBBtn } from "mdb-react-ui-kit";
import React, { useMemo, useState, useEffect } from "react";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import Alerts from "../Alerts";
import URLAPI from "../../helpers/Path";
import { useNavigate } from "react-router-dom";

const New = () => {
  // Data Create
  const [userNew, setUserNew] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  // Messages
  const [msgError, setMsgError] = useState("");

  // Alert
  const [isAlert, setIsAlert] = useState(false);
  const [isAlert2, setIsAlert2] = useState(false);
  const [showPassport, setShowPassport] = useState(false);

  // Page
  const [isCreated, setIsCreated] = useState(false);

  // URL post API PHP
  const urlPhp = URLAPI+"guarda_usuarios.php";

  // const navigate = useNavigate();

  const selectData = useMemo(
    () => [
      { text: "Seleccionar tipo de usuario", value: 0 },
      { text: "Administrador", value: 1 },
      { text: "Supervisor", value: 2 },
      { text: "Auxiliar", value: 3 },
    ],
    []
  );

  const handleSelectChange = (e) => {
    setUserType(parseInt(e.target.value));
  };

  const handleInputChange = (e, type) => {
    switch (type) {
      case "user":
        setMsgError("");
        setUserNew(e.target.value);
        if (e.target.value === "") {
          setMsgError("Campo usuario vacio");
        }
        break;
      case "password":
        setMsgError("");
        setPassword(e.target.value);
        if (e.target.value === "") {
          setMsgError("Campo de contraseña vacio");
        }
        break;
      case "userType":
        setMsgError("");
        setUserType(parseInt(e.target.value));
        if (e.target.value === "") {
          setMsgError("Campo de tipo de usuario vacío");
        }
        break;
      case "name":
        setMsgError("");
        setName(e.target.value);
        if (e.target.value === "") {
          setMsgError("Campo de tipo de usuario vacío");
        }
        break;
      case "email":
        setMsgError("");
        setEmail(e.target.value);
        if (e.target.value === "") {
          setMsgError("Campo de correo vacío");
        }
        break;
      default:
    }
  };

  useEffect(() => {
    if (isCreated) {
      window.location.reload();
    }
  }, [isCreated]);

  const handleSubmitData = async (e) => {
    if (
      name !== "" &&
      userNew !== "" &&
      password !== "" &&
      userType !== 0 &&
      email !== ""
    ) {
      const data = {
        nombre: name,
        usuario: userNew,
        pass: password,
        tipo: userType,
        email: email,
      };
      try {
        const res = await axios.post(urlPhp, data);
        if (res.data > 0) {
          setIsAlert2(true);
        }
      } catch (error) {
        console.error("Error al enviar los datos: ", error);
      }
    } else {
      setIsAlert(true);
    }
  };

  return (
    <>
      <hr />
      {isAlert2 ? (
        <Alerts
          isShow={isAlert2}
          variant="success"
          head="Aviso"
          msg="Usuario creado correctamente"
          clicked={() => setIsCreated(true)}
          textButton="OK"
        />
      ) : (
        <div className="container-fluid">
          <h5 className="mt-3">Creación de usuarios</h5>
          <p>
            Para crear un nuevo usuario, deberá de completar los campos
            requeridos en este formulario, todos los campos son obligatorios, al
            finalizar el llenado presionar el botón de "registrar usuario"
          </p>
          {msgError !== "" && <span>{msgError}</span>}
          <div className=" mt-4">
            <MDBRow>
              <MDBCol>
                <MDBInput
                  label="Nombre completo"
                  type="text"
                  value={name}
                  onChange={(e) => handleInputChange(e, "name")}
                />
              </MDBCol>
              <MDBCol>
                <MDBInput
                  label="Nombre de usuario"
                  type="text"
                  value={userNew}
                  onChange={(e) => handleInputChange(e, "user")}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-3">
              <MDBCol>
                <MDBInput
                  label="Correo electronico"
                  type="email"
                  value={email}
                  onChange={(e) => handleInputChange(e, "email")}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-4">
              <MDBCol sm="7">
                <InputGroup>
                  <FormControl
                    className="form-control"
                    placeholder="Contraseña"
                    type={showPassport ? "text" : "password"}
                    value={password}
                    onChange={(e) => handleInputChange(e, "password")}
                  />
                  <Button
                    variant={showPassport ? "secondary" : "outline-secondary"}
                    onClick={() => setShowPassport(!showPassport)}
                  >
                    <i className="far fa-eye justify-content-center"></i>
                  </Button>
                </InputGroup>
              </MDBCol>
              <MDBCol className="sm">
                <Form.Select
                  className="form-select"
                  onChange={handleSelectChange}
                  value={userType}
                >
                  {selectData.map((selectData) => (
                    <option key={selectData.value} value={selectData.value}>
                      {selectData.text}
                    </option>
                  ))}
                </Form.Select>
              </MDBCol>
              {isAlert && (
                <Alerts
                  isShow={isAlert}
                  variant="danger"
                  head="Error de Registro"
                  msg="Se requieren todos los campos para el registro"
                  clicked={() => setIsAlert(false)}
                  textButton="OK"
                  variantButton="outline-danger"
                />
              )}
            </MDBRow>
            <MDBBtn
              className=" mt-4"
              color="warning"
              onClick={handleSubmitData}
              rounded
            >
              Registrar Usuario
            </MDBBtn>
          </div>
        </div>
      )}
    </>
  );
};

export default New;
