import React from "react";
import { Button } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";

const Alerts = ({isShow, variant, head, msg, clicked, textButton, variantButton}) => {
  return (
    <>
      <Alert show={isShow} variant={variant} className="mt-3">
        <Alert.Heading>{head}</Alert.Heading>
        <p>{msg}</p>
        <hr />
        <div className="d-flex justify-content-end ">
          <Button className="col-md-2" onClick={clicked} variant={variantButton}>
            {textButton}
          </Button>
        </div>
      </Alert>
    </>
  );
};

export default Alerts;
