import {
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBSwitch,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";

const CostsAssignment = ({ onChangeDataCosts }) => {
  // States Validaciones
  const [costSwitch, setCostSwitch] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [msg, setMsg] = useState("");

  // State Costo variable
  const [costVariable, setCostVariable] = useState({
    // Datos en caso de que sea porcentual
    desde: 0, //dato double
    hasta: 0, // dato double
    porcentaje: 0, // dato double
    cuota: 0, // dato double
  });
  // state Costo Fijo
  const [costFijo, setCostFijo] = useState({
    nombre: "",
    tipo_honorario: 0,
    monto: 0,
    // variables: [],
  });

  // State de Lista de costos
  const [costAll, setcostAll] = useState([]);
  const [costAllVariable, setcostAllVariable] = useState([]);

  // Acciones

  // Accion del estado de switch de costo variable y fijo
  // const handleCostSwitch = () => {
  //   setCostSwitch(!costSwitch);
  // };

  const handleOpenCloseModal = () => {
    setOpenModal(!openModal);
  };

  const handleCostSwitch = () => {
    setCostSwitch(!costSwitch);
  };

  const handleInputChange = (e, type) => {
    switch (type) {
      case "costoNombre":
        setCostFijo({ ...costFijo, nombre: e.target.value });
        break;
      case "monto":
        setCostFijo({ ...costFijo, monto: parseInt(e.target.value) });
        break;
      case "desde":
        setCostVariable({ ...costVariable, desde: parseInt(e.target.value) });
        break;
      case "hasta":
        setCostVariable({ ...costVariable, hasta: parseInt(e.target.value) });
        break;
      case "porc":
        setCostVariable({
          ...costVariable,
          porcentaje: parseInt(e.target.value),
        });
        break;
      case "cuota":
        setCostVariable({ ...costVariable, cuota: parseInt(e.target.value) });
        break;
      default:
        break;
    }
  };

  // Boton de agregar todo el costo
  const handleSubmitCost = () => {
    // Validación de costo fijo
    if (costFijo.nombre === "") {
      setMsg("Error: No se ingreso el nombre");
    } else if (
      costFijo.tipo_honorario === 0 &&
      (costFijo.monto === 0 || costFijo.monto == NaN)
    ) {
      setMsg("Error: Ingrese un monto");
      return;
    } else {
      setcostAll((prevCostos) => [...prevCostos, costFijo]);
      setCostFijo({
        nombre: "",
        tipo_honorario: 0,
        monto: 0,
        // variables: [],
      });
    }
  };

  // Descartar item de costo
  const handleDiscardItemCost = (index) => {
    const updatedItemsCost = [...costAll];
    updatedItemsCost.splice(index, 1);
    setcostAll(updatedItemsCost);
  };

  // VARIANTES

  // bitton agregar item de variante
  const handleSubmitCostVariable = () => {
    setcostAllVariable((prevCostosVar) => [...prevCostosVar, costVariable]);
    setCostVariable({
      desde: 0, //dato double
      hasta: 0, // dato double
      porcentaje: 0, // dato double
      cuota: 0, // dato d
    });
  };

  // button Descartar item de variante
  const handelDiscardItemVariant = (index) => {
    const updatedItems = [...costAllVariable];
    updatedItems.splice(index, 1);

    setcostAllVariable(updatedItems);
  };

  // button de guardar array de variantes
  const handleSaveCostsModalBtn = () => {
    if (costAllVariable.length === 0) {
      setMsg("Ingresa al menos un costo variable");
    } else {
      setCostFijo({ ...costFijo, variables: costAllVariable });
      setMsg("");
      handleOpenCloseModal();
    }
  };

  // Effects

  // Mandar datos a la vista anterior de todos los costos
  useEffect(() => {
    onChangeDataCosts(costAll);
  }, [costAll]);

  // Validación si tiene costos
  useEffect(() => {
    if (costAll.length === 0) {
      setMsg("Ingresa al menos un honorario");
    } else {
      setMsg("");
    }
  }, [costAll]);

  // Asignación de si es costo fijo o variable
  useEffect(() => {
    if (costSwitch) {
      setCostFijo({ ...costFijo, tipo_honorario: 1 }); // es variable
    } else {
      setCostFijo({ ...costFijo, tipo_honorario: 0 }); // es fijo
    }
  }, [costSwitch]);

  return (
    <>
      {/* Input de los costos fijos y porcentuales */}
      <MDBRow>
        <MDBCol>
          <h6 style={{ color: "red" }} className=" mb-3">
            {msg}
          </h6>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size={4}>
          <MDBInput
            value={costFijo.nombre}
            onChange={(e) => handleInputChange(e, "costoNombre")}
            label="Ingresa nombre del honorario"
            type="text"
          />
        </MDBCol>
        <MDBCol className="justify-content-end d-flex" size={1}>
          <p>Fijo</p>
        </MDBCol>
        <MDBCol size={2}>
          <MDBSwitch
            id="flexSwitchCheckDefault"
            label="Variable"
            size="lg"
            checked={costSwitch}
            onChange={handleCostSwitch}
          />
        </MDBCol>
        <MDBCol size={3}>
          <MDBInput
            value={costFijo.monto || ""}
            onChange={(e) => handleInputChange(e, "monto")}
            label="Ingresa el monto / %"
            type="number"
          />
        </MDBCol>
        <MDBCol>
          <MDBBtn onClick={handleSubmitCost} size="sm">
            Agregar
          </MDBBtn>
        </MDBCol>
      </MDBRow>

      {/* Lista de costos */}
      {costAll.length > 0 && (
        <MDBRow className="mt-4">
          <MDBCol>
            <h5>#</h5>
          </MDBCol>
          <MDBCol>
            <h5>Nombre </h5>
          </MDBCol>
          <MDBCol>
            <h5>Monto / %</h5>
          </MDBCol>
          <MDBCol>
            <h5>Tipo</h5>
          </MDBCol>
          <MDBCol>
            <h5>Opción</h5>
          </MDBCol>
        </MDBRow>
      )}
      {costAll.map((item, index) => (
        <MDBRow key={index} className="mt-3">
          <MDBCol>
            <p className="mb-1 me-4">{index + 1}</p>
          </MDBCol>
          <MDBCol>
            <p className="mb-1 me-4">{item.nombre}</p>
          </MDBCol>
          <MDBCol>
            {/* {item.tipo_honorario === 0 ? (
              <p className="mb-1 me-4">Fijo</p>
            ) : (
              <p className="mb-1 me-4">Variable</p>
            )} */}
            <p>{item.monto}</p>
          </MDBCol>
          <MDBCol>
            {item.tipo_honorario === 0 ? <p>Fijo</p> : <p>Variable</p>}
          </MDBCol>
          <MDBCol>
            <MDBBtn
              outline
              color="danger"
              onClick={() => handleDiscardItemCost(index)}
            >
              Descartar Honorario
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      ))}

      {/* Modal para los costos variables */}

      <MDBModal show={openModal}>
        <MDBModalDialog centered size="lg">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Crear tabla de costos</MDBModalTitle>
              <MDBBtn
                className="btn btn-close"
                color="none"
                onClick={handleOpenCloseModal}
              ></MDBBtn>
            </MDBModalHeader>
            {/* Contenido y funciones de la creación de la tabla de costos */}
            <MDBModalBody>
              <p>Rellena los campos para agregar costos</p>
              <MDBRow className="mt-4">
                <MDBCol>
                  <MDBInput
                    type="number"
                    label="Desde"
                    onChange={(e) => handleInputChange(e, "desde")}
                    value={costVariable.desde}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    type="number"
                    label="Hasta"
                    onChange={(e) => handleInputChange(e, "hasta")}
                    value={costVariable.hasta}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    type="number"
                    label="Porcentaje %"
                    onChange={(e) => handleInputChange(e, "porc")}
                    value={costVariable.porcentaje}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    type="number"
                    label="Cuota"
                    onChange={(e) => handleInputChange(e, "cuota")}
                    value={costVariable.cuota}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBBtn onClick={handleSubmitCostVariable}>Agregar</MDBBtn>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-3">
                <MDBCol>
                  <h6>Desde</h6>
                </MDBCol>
                <MDBCol>
                  <h6>Hasta</h6>
                </MDBCol>
                <MDBCol>
                  <h6>Porcentaje</h6>
                </MDBCol>
                <MDBCol>
                  <h6>Cuota</h6>
                </MDBCol>
                <MDBCol></MDBCol>
              </MDBRow>
              {costAllVariable.map((item, index) => (
                <MDBRow className="mt-3" key={index}>
                  <MDBCol>
                    <p>{item.desde}</p>
                  </MDBCol>
                  <MDBCol>
                    <p>{item.hasta}</p>
                  </MDBCol>
                  <MDBCol>
                    <p>{item.porcentaje}</p>
                  </MDBCol>
                  <MDBCol>
                    <p>{item.cuota}</p>
                  </MDBCol>
                  <MDBCol>
                    <MDBBtn
                      onClick={(index) => handelDiscardItemVariant(index)}
                      color="danger"
                    >
                      Descartar
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              ))}
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn onClick={handleOpenCloseModal} color="danger">
                Cancelar
              </MDBBtn>
              {/* Boton para guardar la tabla de datos */}
              <MDBBtn onClick={handleSaveCostsModalBtn} color="success">
                Guardar Costos
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default CostsAssignment;
