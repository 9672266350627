import axios from "axios";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Badge, Form, Table } from "react-bootstrap";
import URLAPI from "../../helpers/Path";

const Consult = () => {
  // Listas selects
  const [listRamas, setListRamas] = useState([]);
  const [listLegalActs, setListLegalActs] = useState([]);
  const [listVariants, setListVariants] = useState([]);
  const [listOptions, setListOptions] = useState([]);

  const [selectIdRama, setSelectIdRama] = useState(0);
  const [selectIdActo, setSelectIdActo] = useState(0);
  const [selectIdVariante, setSelectIdVariante] = useState(0);
  const [selectOption, setSelectOption] = useState(0);

  const [isSelectRama, setIsSelectRama] = useState(true);
  const [isSelectActo, setIsSelectActo] = useState(true);
  const [isSelectVariante, setIsSelectVariante] = useState(true);
  const [info, setInfo] = useState([]);
  const [req, setReq] = useState([]);
  const [impuestos, setImpuestos] = useState([]);
  const [honorarios, setHonorarios] = useState([]);

  const [showInfo, setShowInfo] = useState(false);

  const ramasPhp = URLAPI+"lista_ramas.php";
  const getActosPhp = URLAPI+"lista_actos.php";
  const getVariantsPhp = URLAPI+"lista_variantes.php";
  const getOptions = URLAPI+"lista_opciones.php";
  const getAssignments = URLAPI+"datos_asignacion.php";

  useEffect(() => {
    const getRamas = async () => {
      try {
        const res = await axios.get(ramasPhp);
        setListRamas(res.data);
      } catch (error) {
        console.error("Error en la consulta", error);
      }
    };
    getRamas();
  }, []);

  const handleChangeRama = async (e) => {
    setSelectIdActo(0);
    setSelectIdVariante(0);
    setSelectOption(0);
    setShowInfo(false);
    const idRama = parseInt(e.target.value);
    setSelectIdRama(idRama);
    if (idRama > 0) {
      setIsSelectRama(false);
      try {
        const res = await axios.post(getActosPhp, { data: idRama });
        setListLegalActs(res.data);
      } catch (error) {
        console.error("Error en la conexión de BBDD", error);
      }
    } else {
      setIsSelectRama(true);
    }
  };

  const handleChangeActo = (e) => {
    const idActo = e.target.value;
    setSelectIdActo(parseInt(idActo));
    setSelectIdVariante(0);
    setSelectOption(0);
    setShowInfo(false);
  };
  useEffect(() => {
    const getVariantes = async () => {
      if (selectIdActo !== 0) {
        setIsSelectActo(false);
        try {
          const res = await axios.post(getVariantsPhp, { data: selectIdActo });
          const preVariantes = res.data;
          if (preVariantes.length === 0) {
            handleOnConsultar(selectIdActo, 0);
            setIsSelectActo(true);
            setShowInfo(true);
          }
          setListVariants(preVariantes);
        } catch (error) {
          console.error("Error de conexión a la BBDD", error);
        }
      } else {
        setIsSelectActo(true);
        setIsSelectVariante(true);
      }
    };
    getVariantes();
  }, [selectIdActo]);

  const handleChangeVariante = (e) => {
    const idVariante = e.target.value;
    setSelectIdVariante(parseInt(idVariante));
    setIsSelectVariante(false);
    setSelectOption(0);
    setShowInfo(false);
  };
  useEffect(() => {
    const getOpciones = async () => {
      if (selectIdVariante !== 0) {
        const data = {
          id_acto: selectIdActo,
          id_variante: selectIdVariante,
        };
        try {
          const res = await axios.post(getOptions, data);
          const listOptions = res.data;
          setListOptions(listOptions);
          if (listOptions.length === 0) {
            setIsSelectVariante(true);
            handleOnConsultar(selectIdActo, selectIdVariante);
            setShowInfo(true);
          }
        } catch (error) {
          console.error("Error en obtener de opciones", error);
        }
      }
    };
    getOpciones();
  }, [selectIdVariante]);

  const handleChangeOption = (e) => {
    const idOpcionVar = parseInt(e.target.value);
    setSelectOption(idOpcionVar);
    if (idOpcionVar !== 0) {
      handleOnConsultar(selectIdActo, idOpcionVar);
      setShowInfo(true);
    }
  };

  const handleOnConsultar = async (acto, variante) => {
    const data = {
      id_acto: acto,
      id_variante: variante,
    };
    try {
      const res = await axios.post(getAssignments, data);
      setInfo(res.data);
      console.log(res.data);
      setReq(res.data.requisitos.split("\n"));
      setImpuestos(res.data.impuestos);
      setHonorarios(res.data.honorarios);
    } catch (error) {
      console.error("Error en la consulta", error);
    }
  };

  return (
    <>
      <hr />
      <div className="mt-3 container">
        <h5>Consulta de actos jurídicos</h5>
        <p>
          Seleccionar el acto jurídico y sus variantes respectivas para la
          vizualización de honorarios e impuestos
        </p>
        <hr />
        <MDBRow>
          <MDBCol>
            <Form.Select
              className="form-select"
              onChange={handleChangeRama}
              value={selectIdRama}
            >
              <option value={0} key={0}>
                Seleccionar rama
              </option>
              {listRamas.map((items) => (
                <option value={items.id_rama} key={items.id_rama}>
                  {items.nombre}
                </option>
              ))}
            </Form.Select>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-3">
          <MDBCol>
            <Form.Select
              className="form-select"
              disabled={isSelectRama}
              onChange={handleChangeActo}
              value={selectIdActo}
            >
              <option value={0} key={0}>
                Selecciona acto jurídico
              </option>
              {listLegalActs.map((items) => (
                <option key={items.id_acto} value={items.id_acto}>
                  {items.nombre}
                </option>
              ))}
            </Form.Select>
          </MDBCol>
          <MDBCol>
            <Form.Select
              className="form-select"
              disabled={isSelectActo}
              onChange={handleChangeVariante}
              value={selectIdVariante}
            >
              <option value={0} key={0}>
                Selecciona variante
              </option>
              {listVariants.map((items) => (
                <option value={items.id_variante} key={items.id_variante}>
                  {items.nombre}
                </option>
              ))}
            </Form.Select>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-3">
          <MDBCol>
            <Form.Select
              className="form-select"
              disabled={isSelectVariante}
              onChange={handleChangeOption}
              value={selectOption}
            >
              <option value={0} key={0}>
                Selecciona opción
              </option>
              {listOptions.map((items) => (
                <option key={items.id_variante} value={items.id_variante}>
                  {items.nombre}
                </option>
              ))}
            </Form.Select>
          </MDBCol>
        </MDBRow>
        {/* <MDBRow>
          <MDBCol>
            <Button onClick={handleOnClickConsultar}>Consultar</Button>
          </MDBCol>
        </MDBRow> */}
        {showInfo && (
          <>
            <hr className="hr" />
            <MDBRow>
              <MDBCol>
                <Table>
                  <tbody>
                    <tr>
                      <th className="fs-6">Actividad vulnerable</th>
                      {info.vulnerable !== 0 ? (
                        <>
                          <th>
                            <Badge bg="success" className="fs-6">
                              SI
                            </Badge>
                          </th>
                          <th>Umbral: {info.umbral} UMA's</th>
                        </>
                      ) : (
                        <th>
                          <Badge bg="danger" className="fs-6">
                            NO
                          </Badge>
                        </th>
                      )}
                    </tr>
                  </tbody>
                </Table>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-3">
              <MDBCol>
                <h5>Requisitos</h5>
                {info.requisitos !== "" ? (
                  req.map((linea, index) => (
                    <li key={index}>{linea}</li>
                  ))
                ):(<li>Sin requisitos</li>)}
              </MDBCol>
            </MDBRow>
            <hr className="hr" />
            <MDBRow className="mt-3">
              <MDBCol>
                <h5>Impuestos</h5>
                <Table striped>
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th className="text-center">Incluido</th>
                      <th className="text-center">Tipo de impuesto</th>
                      <th className="text-center">Monto</th>
                    </tr>
                  </thead>
                  <tbody>
                    {impuestos.map((items) => (
                      <tr key={items.id_relacion_aji}>
                        <td>{items.nombre}</td>
                        <td className="text-center">
                          {items.seleccionado === 1 ? (
                            <Badge bg="success">SI</Badge>
                          ) : (
                            <Badge bg="danger">NO</Badge>
                          )}
                        </td>
                        <td className="text-center">
                          {parseInt(items.tipo_impuesto) === 1
                            ? "Fijo"
                            : "Variable"}
                        </td>
                        <td className="text-center">${items.monto}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </MDBCol>
            </MDBRow>
            <hr className="hr" />
            <MDBRow className="mt-3">
              <MDBCol>
                <h5>Honorarios</h5>
                <Table striped>
                  <thead>
                    <tr>
                      <th>Concepto</th>
                      <th className="text-center">Tipo de honorario</th>
                      <th className="text-center">Monto / Cuota</th>
                    </tr>
                  </thead>
                  <tbody>
                    {honorarios.map((items) => (
                      <tr key={items.id_honorario}>
                        <td>{items.nombre}</td>
                        {parseInt(items.tipo_honorario) !== 0 ? (
                          <>
                            <td className="text-center">Porcentual</td>
                            <td className="text-center"> {items.monto_cuota}%</td>
                          </>
                        ) : (
                          <>
                            <td className="text-center">Fijo</td>
                            <td className="text-center"> ${items.monto_cuota}</td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </MDBCol>
            </MDBRow>
          </>
        )}
      </div>
    </>
  );
};

export default Consult;
