import axios from "axios";
import {
  MDBBadge,
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import URLAPI from "../../helpers/Path";

const Consult = () => {
  const [ApTitular, setApTitular] = useState("");
  const [data, setData] = useState([]);
  const [searchedNames, setSearchedNames] = useState([]);
  const [listCotizaciones, setlistCotizaciones] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [idCot, setIdCot] = useState(0);
  const [titularSelected, setTitularSelected] = useState("");

  // Modal edición
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [dataCotSelected, setDataCotSelected] = useState([]);
  const [dataCotInm, setDataCotInm] = useState([]);
  const [showInnerHTML, setShowInnerHTML] = useState(false);
  const [responseHTML, setResponseHTML] = useState(undefined);
  const [showModalConfirmEdit, setshowModalConfirmEdit] = useState(false);
  const [idCotNew, setIdCotNew] = useState(0)

  const getName = URLAPI + "lista_titulares.php";
  const postName = URLAPI + "lista_cotizaciones.php";
  const deleteCot = URLAPI + "cancela_cotizacion.php";
  const consultInm = URLAPI + "consulta_inmuebles.php";
  const editInm = URLAPI + "cmb_cotizacion.php";
  const saveCot = URLAPI + "guarda_cotizacion_edit.php";

  useEffect(() => {
    const getNames = async () => {
      try {
        const res = await axios.get(getName);
        setData(res.data);
      } catch (error) {
        console.error("Error en la consulta", error);
      }
    };
    getNames();
  }, []);

  const handleSearchName = (e) => {
    setSearchedNames([]);
    setlistCotizaciones([]);
    setApTitular(e.target.value);
    if (e.key === "Enter") {
      handleBtnSearch();
    }
  };

  const handleBtnSearch = () => {
    data.map((item) => {
      if (item.titular.includes(ApTitular.toUpperCase())) {
        setSearchedNames((prevList) => [...prevList, item]);
      }
      return searchedNames;
    });
  };

  const handleSendName = async (item) => {
    // return console.log(item);
    try {
      const res = await axios.post(postName, item);
      const listCot = res.data;
      setlistCotizaciones(listCot);
      setTitularSelected(item.titular);
    } catch (error) {
      console.error("Error en la consulta", error);
    }
  };
  const handleOnClikPrint = (idCotizacion) => {
    window.open(
      URLAPI + `pdf_cotizacion.php?cotizacion=${idCotizacion}`,
      "_blank"
    );
  };
  const handleOnClickDelete = (item) => {
    setIdCot(item);
    return setShowModal(true);
  };

  const handleBtnCancelar = async () => {
    try {
      await axios.post(deleteCot, { data: idCot.id_cotizacion });
      setShowModal(false);
      window.location.reload();
    } catch (error) {
      console.error("No se pudo hacer la consulta", error);
    }
  };

  const handleOnClickEdit = async (id_cotizacion) => {
    setIdCot(id_cotizacion);
    setResponseHTML(undefined);
    setShowInnerHTML(false);
    try {
      const res = await axios.post(consultInm, {
        id_cotizacion: id_cotizacion,
      });
      setDataCotSelected(res.data);
      setDataCotInm(res.data[1]);
    } catch (error) {
      console.error("No se pudo hacer la consulta", error);
    }
    setShowModalEdit(true);
  };

  const handleUpdateCostOp = (e, index) => {
    const nuevoInmueble = [...dataCotInm];
    nuevoInmueble[index].valor_operacion = parseFloat(e.target.value);
    setDataCotInm(nuevoInmueble);
  };
  const handleUpdateCostCom = (e, index) => {
    const nuevoInmueble = [...dataCotInm];
    nuevoInmueble[index].valor_comercial = parseFloat(e.target.value);
    setDataCotInm(nuevoInmueble);
  };
  const handleUpdateCostCat = (e, index) => {
    const nuevoInmueble = [...dataCotInm];
    nuevoInmueble[index].valor_catastral = parseFloat(e.target.value);
    setDataCotInm(nuevoInmueble);
  };

  const handleClickRecotizar = async () => {
    const inmueble = { ...dataCotInm };
    const data = { inmueble, id_cotizacion: idCot };
    console.log(data);
    try {
      const res = await axios.post(editInm, data);
      setResponseHTML(res.data);
      setShowInnerHTML(true);
    } catch (error) {
      console.error("Error al realizar la consulta:", error);
      setShowInnerHTML(false);
    }
  };

  const baseValor = (val) => {
    console.log(val);
    switch (parseInt(val)) {
      case 1:
        return <p>Municipio de aplicación: Colima - VdeA</p>;
      case 2:
        return <p>Municipio de aplicación: Manzanillo</p>;
      case 3:
        return <p>Municipio de aplicación: Resto Municipios</p>;
      case 4:
        return <p>Municipio de aplicación: Foraneo</p>;
      default:
        break;
    }
  };

  // Guardar cotizacion
  const handleOnClickSave = async () => {
    // Impuestos
    // Separar texto por , y convertirlo en array
    const impuestosNew = document
      .getElementById("hcuales_impuestos")
      .value.split(",");
    // Agregar txt_impuesto
    const addTextImp = impuestosNew.map((item) => `txt_impuesto${item}`);
    // Buscar inputs
    const impComplete = addTextImp.map((item) => {
      return {
        [item]: parseFloat(
          document.getElementById(`${item}`).value.replace(",", "")
        ),
      };
    });

    // Honorarios (montos)
    // Separar texto por , y convertirlo en array
    const honorariosNew = document
      .getElementById("hcuales_honorarios")
      .value.split(",");
    // Agregar txt_honorario
    const addTextHonor = honorariosNew.map((item) => `txt_honorario${item}`);
    // Buscar inputs
    const honorariosList = addTextHonor.map((item) => {
      return {
        [item]: parseFloat(
          document.getElementById(`${item}`).value.replace(",", "")
        ),
      };
    });

    // Honorarios (seleccionados)
    const honorariosSel = addTextHonor.map((item, index) => {
      return {
        [item]: parseInt(
          document.getElementById(`chkhonorario_${index + 1}`).value
        ),
      };
    });

    const data = {
      // id_user: id_user,
      // id_acto: selectActo,
      // id_variante: selectIdVariante,
      // nombre_titular: nombreTitular,
      // base_valor: baseValor,
      id_cotizacion: idCot,
      iva: parseInt(document.getElementById("chkiva").value),
      inmueble: dataCotInm,
      impuestos: impComplete,
      honorarios: honorariosList,
      honorariosSel: honorariosSel,
      hcuantos_honorarios: document.getElementById("hcuantos_honorarios").value,
      hcuales_honorarios: document.getElementById("hcuales_honorarios").value,
    };
    try {
      const res = await axios.post(saveCot, data);
      setIdCotNew(res.data);
      if (res.data > 0) {
        setShowModalEdit(false);
        setshowModalConfirmEdit(true)
      }
    } catch (error) {
      console.error("Error en enviar los datos", error);
    }
  };

  const handlePrint = () => {
    window.open(
      URLAPI + `pdf_cotizacion.php?cotizacion=${idCotNew}`,
      "_blank"
    );
    // printWindow.close()
    // printWindow.print()
    setshowModalConfirmEdit(false);
    window.location.reload();
  };

  return (
    <>
      {/* Modal de cancelación */}
      <Modal
        size="lg"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header>
          <Modal.Title>Aviso de Cotización</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Está seguro que desea cancelar permanentemente la cotización?
        </Modal.Body>
        <Modal.Footer className="mx-100">
          <MDBBtn
            className=""
            color="danger"
            onClick={() => setShowModal(false)}
          >
            No
          </MDBBtn>
          <MDBBtn color="success" onClick={handleBtnCancelar}>
            Sí
          </MDBBtn>
        </Modal.Footer>
      </Modal>
      {/* Modal de edición */}
      <Modal
        size="lg"
        centered
        show={showModalEdit}
        // onHide={() => setShowModalEdit(false)}
      >
        <Modal.Header>
          <Modal.Title>Edición de Cotización</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MDBRow>
            <MDBCol>
              Titular:
              <MDBBadge className="fs-6 m-2 badge badge-dark" color="dark">
                {" "}
                {titularSelected}
              </MDBBadge>
            </MDBCol>
            <MDBCol className="m-2 d-flex justify-content-end">
              {dataCotSelected.length > 0 && (
                <p className="fw-2">Fecha: {dataCotSelected[0].fecha}</p>
              )}
            </MDBCol>
          </MDBRow>
          {dataCotSelected.length > 0 && (
            <>
              <p>
                Acto / Variante: {dataCotSelected[0].elacto} ,{" "}
                {dataCotSelected[0].lavariante}
              </p>

              {/* <p>Municipio de aplicación: {dataCotSelected[0].base_valor}</p> */}
              {baseValor(dataCotSelected[0].base_valor)}
              {dataCotSelected[1].length !== 0 && (
                <>
                  <hr className="hr" />
                  <p>No. de inmuebles: {dataCotSelected[1].length}</p>
                </>
              )}

              {/* Inputs editables de los inmuebles  */}
              {dataCotInm.map((item, index) => (
                <MDBRow key={item.no_inmueble} className="mt-1 mb-1">
                  <MDBCol>
                    <p>Inmueble {item.no_inmueble}: </p>
                  </MDBCol>
                  <MDBCol>
                    <MDBInput
                      label="Valor operación"
                      value={item.valor_operacion || ""}
                      onChange={(e) => handleUpdateCostOp(e, index)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <MDBInput
                      label="Valor comercial"
                      value={item.valor_comercial || ""}
                      onChange={(e) => handleUpdateCostCom(e, index)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <MDBInput
                      label="Valor catastral"
                      value={item.valor_catastral || ""}
                      onChange={(e) => handleUpdateCostCat(e, index)}
                    />
                  </MDBCol>
                </MDBRow>
              ))}
            </>
          )}
          <hr />
          <div className="d-flex justify-content-end">
            <MDBBtn
              className="m-1"
              color="danger"
              onClick={() => {
                setDataCotSelected([]);
                setShowModalEdit(false);
              }}
            >
              Cancelar
            </MDBBtn>
            <MDBBtn
              className="btn btn-success m-1"
              onClick={handleClickRecotizar}
            >
              Re-cotizar
            </MDBBtn>
          </div>
          {showInnerHTML && (
            <>
              <div dangerouslySetInnerHTML={{ __html: responseHTML }} />
              <MDBRow className="text-center">
                <MDBCol>
                  <MDBBtn
                    className="btn btn-warning d-grid gap-2 col-6 mx-auto mt-3"
                    onClick={handleOnClickSave}
                  >
                    Guardar cotización
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="mx-100"></Modal.Footer>
      </Modal>
      <div>
        <Modal
          size="lg"
          centered
          show={showModalConfirmEdit}
        >
          <Modal.Header>
            <Modal.Title>Aviso de Cotización</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            La cotización se ha guardado con éxito, ¿Desea imprimir la
            cotización?
          </Modal.Body>
          <Modal.Footer className="mx-100">
            <MDBBtn
              className=""
              color="danger"
              onClick={() => window.location.reload()}
            >
              No, solo guardar
            </MDBBtn>
            <MDBBtn color="success" onClick={handlePrint}>
              Sí, imprimir PDF
            </MDBBtn>
          </Modal.Footer>
        </Modal>
      </div>
      <h5>Consulta / Cancelación de cotizaciones</h5>
      <p>
        Escribir apellido paterno del titular para la busqueda de cotizaciones
      </p>
      <MDBRow className="mt-4">
        <MDBCol>
          <MDBInput
            label="Apellido Paterno"
            onChange={handleSearchName}
            onKeyDown={handleSearchName}
          />
        </MDBCol>
        <MDBBtn className="col-2" onClick={handleBtnSearch}>
          Buscar
        </MDBBtn>
      </MDBRow>
      <MDBRow className="mt-3">
        {searchedNames.length > 0 && (
          <div>
            <h6 className=" p-2 border-bottom">CLIENTES</h6>
            <div className="d-grid gap-2">
              {searchedNames.map((item, index) => (
                <MDBBtn
                  className=" btn btn-secondary text-start"
                  key={index}
                  onClick={() => handleSendName(item)}
                >
                  {item.titular}
                </MDBBtn>
              ))}
            </div>
          </div>
        )}

        {listCotizaciones.length > 0 && (
          <div className="mt-3">
            <h5 className="p-2 border-bottom">COTIZACIONES</h5>
            <MDBBadge className="p-2 mb-2 ms-2" color="dark">
              {" "}
              {titularSelected}
            </MDBBadge>
            <table className="table ">
              <thead className="table-success">
                <tr>
                  <th>#</th>
                  <th>Acto</th>
                  <th>Variante</th>
                  <th className="col-2">Fecha</th>
                  <th>Estatus</th>
                  <th>Opciones</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {listCotizaciones.map((item, index) => (
                  <tr key={item.id_cotizacion}>
                    <th>{item.id_cotizacion}</th>
                    <th>{item.elacto}</th>
                    <th>{item.lavariante}</th>
                    <th>{item.fecha}</th>
                    {item.vigente > 0 ? (
                      <th>
                        <Badge bg="success">Vigente</Badge>
                      </th>
                    ) : (
                      <th>
                        <Badge bg="danger">Cancelada</Badge>
                      </th>
                    )}
                    <th>
                      <MDBBtn
                        disabled={item.vigente > 0 ? false : true}
                        className={`btn btn-${
                          item.vigente > 0 ? "warning" : "secondary"
                        }`}
                        onClick={() => handleOnClickEdit(item.id_cotizacion)}
                      >
                        <MDBIcon fas icon="edit" className="p-2" />
                      </MDBBtn>
                    </th>
                    <th className="">
                      <MDBBtn
                        className=""
                        onClick={() => handleOnClikPrint(item.id_cotizacion)}
                      >
                        <MDBIcon fas icon="print" className="p-2" />
                      </MDBBtn>
                    </th>

                    <>
                      <th className="">
                        <MDBBtn
                          disabled={item.vigente > 0 ? false : true}
                          className={`btn btn-${
                            item.vigente > 0 ? "danger" : "secondary"
                          }`}
                          onClick={() => handleOnClickDelete(item)}
                        >
                          <MDBIcon fas icon="ban" className="p-2" />
                        </MDBBtn>
                      </th>
                    </>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </MDBRow>
    </>
  );
};

export default Consult;
