import React, { useState, useEffect } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import logoLogin from "../assets/LOGO NOT4RIA.png";
import "../styles/login.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import URLAPI from "../helpers/Path";

const Login = () => {
  // Data Login
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [typeUser, setTypeUser] = useState(null);
  const [idUser, setIdUser] = useState(0)

  // Messages
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");
  

  //Alert
  const [isAlert, setIsAlert] = useState(false);

  // Url of API PHP
  
  const urlPhp = URLAPI+"logeo.php";

  //Navigate
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setMsg("");
    }, 500);
  });

  // Acceso al sistema
  useEffect(() => {
    switch (typeUser) {
      case "1":
        navigate(`/Admin/${idUser}`);
        break;
      case "2":
        navigate(`/Supervisor/${idUser}`);
        break;
      case "3":
        navigate(`/auxiliar/${idUser}`,);
        break;
      default:
        setMsg('Ingresa tus datos')
        break;
    }
  }, [typeUser]);

  const handleInputChange = (e, type) => {
    switch (type) {
      case "user":
        setError("");
        setUser(e.target.value);
        if (e.target.value === "") {
          setError("Campo de usuario vacio");
        }
        break;
      case "password":
        setError("");
        setPassword(e.target.value);
        if (e.target.value === "") {
          setError("Campo de contraseña esta vacio");
        }
        if(e.key === 'Enter'){
          loginSubmit()
        }
        break;
      default:
    }
  };
  const loginSubmit = async (e) => {
    if (user !== "" && password !== "") {
      const data = {
        user: user,
        pass: password,
      };
      setUser("");
      setPassword("");
      

      // aqui se mandan los datos al archivo php
      try {
        const res = await axios.post(urlPhp, data);
        setTypeUser(res.data.id_tipo)
        setIdUser(res.data.id_usuario)
        // console.log(res.data)
        if (res.data === 0) {
          setIsAlert(true)
        }
      } catch (error) {
        console.error("Error al enviar los datos: ", error);
      }
    } else {
      setError("¡Todos los campos son requeridos!");
    }
  };

  return (
    <MDBContainer fluid>
      <MDBRow className="d-flex justify-content-center align-items-center h-100">
        <MDBCol col="12">
          <MDBCard
            className="bg-white my-5 mx-auto shadow-5-strong"
            style={{ borderRadius: "1rem", maxWidth: "500px" }}
          >
            <MDBCardBody className="p-5 w-100 d-flex flex-column">
              <img src={logoLogin} className="img-fluid" alt="" />
              <h2 className="fw-bold m-5 text-center">Iniciar sesión</h2>
              <p>
                {error !== "" ? (
                  <span className="error">{error}</span>
                ) : (
                  <span className="sucess">{msg}</span>
                )}
              </p>
              <MDBInput
                wrapperClass="mb-4 w-100"
                label="Usuario"
                type="text"
                size="lg"
                value={user}
                onChange={(e) => handleInputChange(e, "user")}
              />
              <MDBInput
                wrapperClass="mb-4 w-100"
                label="Contraseña"
                type="password"
                size="lg"
                value={password}
                onChange={(e) => handleInputChange(e, "password")}
                onKeyDown={(e) => handleInputChange(e, "password")}
              />
              <MDBBtn
                size="lg"
                onClick={loginSubmit}
                className="btn btn-warning btn-rounded mb-3"
              >
                Ingresar
              </MDBBtn>
              {isAlert && (
                <Alert
                  variant="danger"
                  onClose={() => setIsAlert(false)}
                  dismissible
                >
                <Alert.Heading className="mt-3" >Error de inicio de sesión</Alert.Heading>
                  <p>El nombre de usuario o la contraseña son incorrectos.</p>
                </Alert>
              )}
              <hr className="my-4" />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Login;
