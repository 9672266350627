import React from "react";
import logoMenu from "../assets/LOGO NOT4RIA PABLO.jpg";

const DefaultMenuScreen = () => {
  return (
    <>
      <div className="text-center align-items-center">
        <img src={logoMenu} className="img-fluid m-5" alt="" width='700px' />
      </div>
    </>
  );
};

export default DefaultMenuScreen;
