import { MDBBtn } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";

const ListaActosNew = ({ variablesAJ, onChangeDataVariable }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(variablesAJ);
  }, [variablesAJ]);

  const handleDiscard = (index) => {
    const updatedItems = [...data];
    updatedItems.splice(index, 1);

    setData(updatedItems);
    onChangeDataVariable(updatedItems);
  };

  return (
    <>
      <table className="table align-middle mb-0 bg-white">
        <thead className="bg-light">
          <tr>
            <th>
              {" "}
              <h5>LISTADO DE VARIANTES</h5>{" "}
              
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>
                <div className="ms-3">
                  <p className="mb-1 me-4">{item}</p>
                </div>
              </td>
              <td className="d-flex justify-content-end">
                <div>
                  <MDBBtn
                    color="danger"
                    className="btn btn-sm"
                    onClick={() => handleDiscard(index)}
                  >
                    Descartar
                  </MDBBtn>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ListaActosNew;
