import axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import Alerts from "../Alerts";
import URLAPI from "../../helpers/Path";

const Delete = () => {
  const [user, setUser] = useState([]);
  const [selectionUser, setSelectionUser] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isCreated, setIsCreated] = useState(false);

  const urlPhp = URLAPI+"lista_usuarios.php";
  const DeleteUser = URLAPI+"borra_usuarios.php";

  const handleSelectChange = (e) => {
    setSelectionUser(parseInt(e.target.value));
  };

  // Se manda el post para la eliminacion del usuario
  const handleSubmitDelete = async () => {
    try {
      const res = await axios.post(DeleteUser, selectionUser);
      console.log(res.data);
      setIsActive(false);
      setSelectionUser(0);
      setIsActive2(true);
    } catch (error) {
      console.error("Error al mandar los datos", error);
    }
  };

  // Obtener lista de usuarios para desplegar en select
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(urlPhp);
        setUser(res.data);
      } catch (error) {
        console.error("No se pudo cargar los datos", error);
      }
    };
    getData();
  }, []);

  // Si se elimina correctamente se recarga la pagina
  useEffect(() => {
    if (isCreated) {
      window.location.reload();
    }
  }, [isCreated]);

  //Post el usuario seleccionado y regresar el usuario seleccionado
  useEffect(() => {
    if (selectionUser > 0) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [selectionUser]);

  return (
    <>
      <hr />
      {isActive2 ? (
        <Alerts
          isShow={isActive2}
          variant="success"
          head="Aviso"
          msg="Usuario eliminado"
          clicked={() => setIsCreated(true)}
          textButton="OK"
          variantButton="outline-success"
        />
      ) : (
        <div>
          <h5 className="mt-3">Baja de usuarios</h5>
          <p>
            Seleccionar el usuario que desea eliminar
          </p>
          <Form.Select
            className="form-select"
            onChange={handleSelectChange}
            value={selectionUser}
          >
            <option value="0">Selecciona el usuario</option>
            {user.map((user) => (
              <option key={user.id_usuario} value={user.id_usuario}>
                {user.login}
              </option>
            ))}
          </Form.Select>
          {isActive && (
            <Alert show={isActive} variant="warning" className="mt-3">
              <Alert.Heading>Alerta</Alert.Heading>
              <p>¿Estás seguro de eliminar este usuario?</p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button
                  onClick={() => setSelectionUser(0)}
                  variant="outline-danger"
                  className="m-2 col-md-2"
                >
                  NO
                </Button>
                <Button
                  onClick={handleSubmitDelete}
                  variant="outline-success"
                  className="m-2 col-md-2"
                >
                  SI
                </Button>
              </div>
            </Alert>
          )}
        </div>
      )}
    </>
  );
};

export default Delete;
