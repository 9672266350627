import axios from "axios";
import { MDBBtn, MDBCol, MDBInput, MDBRow } from "mdb-react-ui-kit";
import Modal from "react-bootstrap/Modal";
import React, { useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import URLAPI from "../../helpers/Path";

const FormQua = ({
  resImpuesto,
  nombreTitular,
  selectRama,
  selectActo,
  selectIdVariante,
  id_user,
}) => {
  // States
  const [inputNumeroInmuebles, setInputNumeroInmuebles] = useState("");
  const [baseValor, setBaseValor] = useState(0);
  const [msg, setMsg] = useState("");
  const [inmueble, setInmueble] = useState([
    // {
    //   valorOp: 0,
    //   valorComercial: 0,
    //   valorCatastral: 0,
    // },
  ]);

  const [resnumber, setResnumber] = useState(0);
  const [resPonse, setResPonse] = useState(undefined);

  const [showIframe, setShowIframe] = useState(false);
  const [showIframe2, setShowIframe2] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const selectData = useMemo(
    () => [
      { text: "Colima - VdeA", value: 1 },
      { text: "Manzanillo", value: 2 },
      { text: "Rto Municipios", value: 3 },
      { text: "Foráneos", value: 4 },
    ],
    []
  );

  const getCot = URLAPI + "cotizacion.php";
  const saveCot = URLAPI + "guarda_cotizacion.php";

  // Funcionalidad de los inputs por cada inmueble y su array
  const handleInputNoIn = (e) => {
    const input = parseInt(e.target.value);
    if (/^\d{0,2}$/.test(input)) {
      setInputNumeroInmuebles(input);
    }
    setInmueble((prevIn) => {
      const nuevosInmuebles = [...prevIn];

      for (let i = nuevosInmuebles.length; i < input; i++) {
        nuevosInmuebles.push({
          valorOp: 0,
          valorComercial: 0,
          valorCatastral: 0,
        });
      }

      nuevosInmuebles.splice(input);

      return nuevosInmuebles;
    });
  };

  // hanndleChange de campos del inmueble
  const handleChangeOperacion = (e, index) => {
    const nuevoInmueble = [...inmueble];
    nuevoInmueble[index].valorOp = parseFloat(e.target.value);
    setInmueble(nuevoInmueble);
  };
  const handleChangeComercial = (e, index) => {
    const nuevoInmueble = [...inmueble];
    nuevoInmueble[index].valorComercial = parseFloat(e.target.value);
    setInmueble(nuevoInmueble);
  };
  const handleChangeCatastral = (e, index) => {
    const nuevoInmueble = [...inmueble];
    nuevoInmueble[index].valorCatastral = parseFloat(e.target.value);
    setInmueble(nuevoInmueble);
  };

  // Componente del item Inmueble (se repite cada que interactua el input del numero de muebles)
  const renderComponents = () => {
    const componentes = [];
    for (let index = 0; index < inputNumeroInmuebles; index++) {
      componentes.push(
        <MDBRow className="mt-1" key={index}>
          <MDBCol>
            <p>Inmueble {index + 1}</p>
          </MDBCol>
          <MDBCol>
            <MDBInput
              label="Valor operación"
              onChange={(e) => handleChangeOperacion(e, index)}
              value={inmueble.valorOp}
              type="number"
              min={0}
            />
          </MDBCol>
          <MDBCol>
            <MDBInput
              label="Valor comercial"
              onChange={(e) => handleChangeComercial(e, index)}
              value={inmueble.valorComercial}
              type="number"
              min={0}
            />
          </MDBCol>
          <MDBCol>
            <MDBInput
              label="Valor catastral"
              onChange={(e) => handleChangeCatastral(e, index)}
              value={inmueble.valorCatastral}
              type="number"
              min={0}
            />
          </MDBCol>
        </MDBRow>
      );
    }
    return componentes;
  };

  //   handle de Base valor del calculo
  const handleBaseValorSelect = (e) => {
    const inputSelect = e.target.value;
    setBaseValor(parseInt(inputSelect));
  };

  //   Verificar si algun campo del inmueble esta vacio
  const basicIsNotEmpty = () => {
    if (inmueble.length !== 0 && baseValor !== 0 && nombreTitular !== "") {
      return true;
    } else {
      return false;
    }
  };

  // validacion de campos de los inmuebles
  const inmuebleIsNotEmpty = (inmuebles) => {
    const array = inmuebles.map((item) => {
      if (
        item.valorOp === 0 &&
        item.valorComercial === 0 &&
        item.valorCatastral === 0
      ) {
        // setIsEmptyTaxes(true);
        return false;
      } else {
        return true;
      }
    });
    return array;
  };

  // Validación de campo catastral en caso que sea Manzanillo
  const catastralIsEmpty = (inmuebles) => {
    const array = inmuebles.map((item) => {
      if (item.valorCatastral === 0 && baseValor === 1) {
        return false;
      } else {
        return true;
      }
    });
    return array;
  };

  // HandleClickCotizarForm
  const handleClickCotizar = async () => {
    const data = {
      id_user: id_user,
      nombre_titular: nombreTitular,
      id_rama: selectRama,
      id_acto: selectActo,
      id_variante: selectIdVariante,
      inmueble: inmueble,
      base_valor: baseValor,
    };
    const arrayValidacionCampos = inmuebleIsNotEmpty(inmueble);
    const arrayValidacionCatastral = catastralIsEmpty(inmueble);
    if (
      basicIsNotEmpty() &&
      !arrayValidacionCampos.some((element) => element === false) &&
      !arrayValidacionCatastral.some((element) => element === false)
    ) {
      // console.log("Datos validados: Mandando datos");
      const res = await axios.post(getCot, data);
      setResPonse(res.data);
      setShowIframe(true);
      setMsg("");
      setTimeout(() => {
        window.scroll({
          top: document.body.offsetHeight,
          left: 0,
          behavior: "smooth",
        });
      }, 500);
    } else {
      setMsg("ADVERTENCIA: Campos incompletos, verifica tus datos");
    }
  };

  const handleCLickSinInmbuebles = async () => {
    const data = {
      id_user: id_user,
      nombre_titular: nombreTitular,
      id_rama: selectRama,
      id_acto: selectActo,
      id_variante: selectIdVariante,
      inmueble: 0,
      base_valor: baseValor,
    };
    try {
      const res = await axios.post(getCot, data);
      setResPonse(res.data);
      setShowIframe2(true);
      setTimeout(() => {
        window.scroll({
          top: document.body.offsetHeight,
          left: 0,
          behavior: "smooth",
        });
      }, 500);
    } catch (error) {
      console.error("No se pudo mandar los datos", error);
      setShowIframe2(false);
    }
  };
  const handleOnClickSave = async () => {
    // Impuestos
    // Separar texto por , y convertirlo en array
    const impuestosNew = document
      .getElementById("hcuales_impuestos")
      .value.split(",");
    // Agregar txt_impuesto
    const addTextImp = impuestosNew.map((item) => `txt_impuesto${item}`);
    // Buscar inputs
    const impComplete = addTextImp.map((item) => {
      return {
        [item]: parseFloat(
          document.getElementById(`${item}`).value.replace(",", "")
        ),
      };
    });

    // Honorarios (montos)
    // Separar texto por , y convertirlo en array
    const honorariosNew = document
      .getElementById("hcuales_honorarios")
      .value.split(",");
    // Agregar txt_honorario
    const addTextHonor = honorariosNew.map((item) => `txt_honorario${item}`);
    // Buscar inputs
    const honorariosList = addTextHonor.map((item) => {
      return {
        [item]: parseFloat(
          document.getElementById(`${item}`).value.replace(",", "")
        ),
      };
    });

    // Honorarios (seleccionados)
    const honorariosSel = addTextHonor.map((item, index) => {
      return {
        [item]: parseInt(
          document.getElementById(`chkhonorario_${index + 1}`).value
        ),
      };
    });

    const data = {
      id_user: id_user,
      nombre_titular: nombreTitular,
      id_acto: selectActo,
      id_variante: selectIdVariante,
      base_valor: baseValor,
      iva: parseInt(document.getElementById("chkiva").value),
      inmueble: inmueble,
      impuestos: impComplete,
      honorarios: honorariosList,
      honorariosSel: honorariosSel,
      hcuantos_honorarios: document.getElementById("hcuantos_honorarios").value,
      hcuales_honorarios: document.getElementById("hcuales_honorarios").value,
    };
    try {
      const res = await axios.post(saveCot, data);
      setResnumber(res.data);
      if (res.data > 0) {
        setShowModal(true);
      } else {
        setShowModal(false);
      }
    } catch (error) {
      console.error("Error en enviar los datos", error);
    }
  };

  const handlePrint = () => {
    window.open(
      URLAPI + `pdf_cotizacion.php?cotizacion=${resnumber}`,
      "_blank"
    );
    // printWindow.close()
    // printWindow.print()
    setShowModal(false);
    window.location.reload();
  };

  return (
    <>
      <div>
        <Modal
          size="lg"
          centered
          show={showModal}
          onHide={() => setShowModal(false)}
        >
          <Modal.Header>
            <Modal.Title>Aviso de Cotización</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            La cotización se ha guardado con éxito, ¿Desea imprimir la
            cotización?
          </Modal.Body>
          <Modal.Footer className="mx-100">
            <MDBBtn
              className=""
              color="danger"
              onClick={() => window.location.reload()}
            >
              No, solo guardar
            </MDBBtn>
            <MDBBtn color="success" onClick={handlePrint}>
              Sí, imprimir PDF
            </MDBBtn>
          </Modal.Footer>
        </Modal>
      </div>
      {resImpuesto !== 0 ? (
        <div className="mt-4">
          <MDBRow className="mb-3">
            <MDBCol>
              <h5>Número de inmuebles / fracciones</h5>
            </MDBCol>
            <MDBCol>
              <MDBInput
                type="number"
                onChange={handleInputNoIn}
                value={inputNumeroInmuebles}
                min={1}
                maxLength={2}
              />
            </MDBCol>
          </MDBRow>
          {/* Inmuebles */}
          <div>{renderComponents()}</div>
          <MDBRow className="mt-3">
            <MDBCol size={3}>
              <h5>Municipio de aplicación</h5>
            </MDBCol>
            <MDBCol>
              <Form.Select
                className="form-select"
                onChange={handleBaseValorSelect}
                value={baseValor}
              >
                <option value={0} key={0}>
                  Selecciona municipio
                </option>
                {selectData.map((items) => (
                  <option value={items.value} key={items.value}>
                    {items.text}
                  </option>
                ))}
              </Form.Select>
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-4">
            {msg !== "" && <p style={{ color: "red" }}>{msg}</p>}
            <MDBBtn rounded onClick={handleClickCotizar} color="success">
              Cotizar
            </MDBBtn>
          </MDBRow>
          {showIframe && (
            <>
              <MDBRow className="mt-5">
                <MDBCol>
                  <div dangerouslySetInnerHTML={{ __html: resPonse }} />
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-4">
                <MDBBtn onClick={handleOnClickSave}>Guardar Cotización</MDBBtn>
              </MDBRow>
            </>
          )}
        </div>
      ) : (
        <>
          <MDBRow className="mt-4">
            <MDBBtn color="success" onClick={handleCLickSinInmbuebles}>
              Cotizar
            </MDBBtn>
          </MDBRow>

          {showIframe2 && (
            <>
              <MDBRow className="mt-4 justify-content-center align-items-center">
                <div dangerouslySetInnerHTML={{ __html: resPonse }} />
              </MDBRow>
              <MDBRow className="mt-4">
                <MDBBtn onClick={handleOnClickSave}>Guardar Cotización</MDBBtn>
              </MDBRow>
            </>
          )}
        </>
      )}
    </>
  );
};

export default FormQua;
