import axios from "axios";
import React, { useEffect, useState, useMemo } from "react";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { MDBCol, MDBInput, MDBRow, MDBBtn } from "mdb-react-ui-kit";
import Alerts from "../Alerts";
import URLAPI from "../../helpers/Path";

const Edit = () => {
  const [dataUser, setDataUser] = useState([]);
  const [getUser, setGetUser] = useState({
    id_usuario: 0,
    login: "",
    pass: "",
    id_tipo: 0,
    nombre: "",
    vigente: 0,
  });
  const [selectionUser, setSelectionUser] = useState(0);

  const [isAlert, setIsAlert] = useState(false);
  const [password, setPassword] = useState("");
  const [isAlert2, setIsAlert2] = useState(false);

  const [isCreated, setIsCreated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [msgError, setMsgError] = useState("");

  const GetUserUrlPhp = URLAPI+"select_usuario.php";
  const editUrl = URLAPI+"actualiza_usuarios.php";
  const urlPhp = URLAPI+"lista_usuarios.php";

  const selectData = useMemo(
    () => [
      { text: "Seleccionar tipo de usuario", value: 0 },
      { text: "Administrador", value: 1 },
      { text: "Supervisor", value: 2 },
      { text: "Auxiliar", value: 3 },
    ],
    []
  );

  // FUncionalidad de select de usuario
  const handleSelectChange = (e) => {
    setSelectionUser(parseInt(e.target.value));
  };

  // Aqui se obtiene la lista de usuarios para el select
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(urlPhp);
        setDataUser(res.data);
      } catch (error) {
        console.error("No se pudo cargar los datos", error);
      }
    };
    getData();
  }, []);

  // Aqui se obtiene la respuesta del select y regresa el usuario seleccionado
  useEffect(() => {
    const handleGetResponse = async () => {
      try {
        const res = await axios.post(GetUserUrlPhp, { data: selectionUser });
        const user = res.data;
        setGetUser({
          id_usuario: user.id_usuario,
          login: user.login,
          pass: user.pass,
          id_tipo: parseInt(user.id_tipo),
          nombre: user.nombre,
          vigente: user.vigente,
          email: user.email,
        });
      } catch (error) {
        console.error("Error al enviar los datos: ", error);
      }
    };
    handleGetResponse();
  }, [selectionUser]);

  // Funcionalidad inputs
  const handleInputChange = async (e, type) => {
    switch (type) {
      case "user":
        setMsgError("");
        setGetUser({ ...getUser, login: e.target.value });
        if (e.target.value === "") {
          setMsgError("Campo usuario vacio");
        }
        break;
      case "password":
        setMsgError("");
        const newPassword = e.target.value;
        setPassword(newPassword);
        setGetUser({ ...getUser, pass: newPassword });
        break;
      case "name":
        setMsgError("");
        setGetUser({ ...getUser, nombre: e.target.value });
        if (e.target.value === "") {
          setMsgError("Campo de tipo de usuario vacío");
        }
        break;
      case "email":
        setMsgError("");
        setGetUser({ ...getUser, email: e.target.value });
        if (e.target.value === "") {
          setMsgError("Campo de correo electronico está vacío");
        }
        break;
      default:
    }
  };
  // Submit edit
  const handleSelectChangeType = (e) => {
    setGetUser({ ...getUser, id_tipo: parseInt(e.target.value) });
  };

  const handleSubmitData = async (e) => {
    if (getUser.name !== "" && getUser.login !== "" && getUser.id_tipo !== 0) {
      // console.log(getUser);
      try {
        const data = getUser;
        const res = await axios.post(editUrl, data);
        console.log(res.data);
        if (res.data > 0) {
          setIsAlert2(true);
        }
      } catch (error) {
        console.error("Error al enviar los datos: ", error);
      }
      setSelectionUser(0);
    } else {
      setIsAlert(true);
    }
  };
  useEffect(() => {
    if (isCreated) {
      window.location.reload();
    }
  }, [isCreated]);

  return (
    <>
      <hr />
      {/* Extraer datos y mostrarlos en un select */}
      <div>
        <h5 className="mt-3">Edición de usuarios</h5>
        <p>Seleccionar el usuario que requiera editar</p>
        <p>
          NOTA: Si desea mantener la misma contraseña, dejar el campo en blanco{" "}
        </p>
        <hr />
        {msgError !== "" && <span>{msgError}</span>}
        <Form.Select
          className="form-select"
          onChange={handleSelectChange}
          value={selectionUser}
        >
          <option value="0">Selecciona el usuario</option>
          {dataUser.map((dataUser) => (
            <option key={dataUser.id_usuario} value={dataUser.id_usuario}>
              {dataUser.login}
            </option>
          ))}
        </Form.Select>
      </div>
      {isAlert2 && (
        <Alerts
          isShow={isAlert2}
          variant="success"
          head="Aviso"
          msg="Usuario editado correctamente"
          clicked={() => setIsCreated(true)}
          textButton="OK"
        />
      )}
      {/* Formulario de Edicion */}
      {selectionUser !== 0 && (
        <div>
          <div className="container-fluid mt-4">
            <MDBRow>
              <MDBCol>
                <MDBInput
                  name="nombre"
                  label="Nombre Completo"
                  type="text"
                  value={getUser.nombre}
                  onChange={(e) => handleInputChange(e, "name")}
                />
              </MDBCol>
              <MDBCol>
                <MDBInput
                  name="login"
                  label="Nombre de Usuario"
                  type="text"
                  value={getUser.login}
                  onChange={(e) => handleInputChange(e, "user")}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-3">
              <MDBCol>
                <MDBInput
                  name="email"
                  label="Correo electronico"
                  type="email"
                  value={getUser.email}
                  onChange={(e) => handleInputChange(e, "email")}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-4">
              <MDBCol sm="7">
                <InputGroup>
                  <FormControl
                    name="pass"
                    placeholder="Contraseña"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => handleInputChange(e, "password")}
                  />
                  <Button
                    variant={showPassword ? "secondary" : "outline-secondary"}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <i className="far fa-eye justify-content-center"></i>
                  </Button>
                </InputGroup>
              </MDBCol>
              <MDBCol className="sm">
                <Form.Select
                  className="form-select"
                  onChange={handleSelectChangeType}
                  value={getUser.id_tipo}
                >
                  {selectData.map((selectData) => (
                    <option key={selectData.value} value={selectData.value}>
                      {selectData.text}
                    </option>
                  ))}
                </Form.Select>
              </MDBCol>
              {isAlert && (
                <Alerts
                  isShow={isAlert}
                  variant="danger"
                  head="Error de Registro"
                  msg="Se requieren todos los campos para el registro"
                  clicked={() => setIsAlert(false)}
                  textButton="OK"
                  variantButton="outline-danger"
                />
              )}
            </MDBRow>
            <MDBBtn
              className=" mt-4"
              color="warning"
              onClick={handleSubmitData}
              rounded
            >
              Realizar cambios
            </MDBBtn>
          </div>
        </div>
      )}
    </>
  );
};

export default Edit;
