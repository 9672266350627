import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "./screens/Login";
import Admin from "./screens/Admin";
import Supervisor from "./screens/Supervisor";
import Auxiliar from "./screens/Auxiliar";
function App() {

  // Manejo de sesión

  return (
    <Routes>
        <Route exact path="/" element={<Login />} />
        <Route path = "/admin/:id_user" element={<Admin /> } />
        <Route path = "/supervisor/:id_user" element={<Supervisor />}/>
        <Route path = "/auxiliar/:id_user" element={<Auxiliar />} />
    </Routes>

  );
}

export default App;
