import React, { useState } from "react";
import { MDBIcon } from "mdb-react-ui-kit";

const CustomDropdown = ({
  title,
  items,
  iconMDB,
  onSelectOpt,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (item, index) => {
    onSelectOpt(title, item, index);
    toggleDropdown();
  };

  return (
    <div>
      <li className="nav-item">
        <button
          href=" "
          className="nav-link active  w-100 text-start fs-5 dropdown-toggle"
          onClick={toggleDropdown}
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <MDBIcon fas icon={iconMDB} className="m-2" />
          <span>{title}</span>
        </button>
        {isOpen && (
          <ul className="drowdown-menu">
            {items.map((item, index) => (
              <li className="dropdown-item m-2" key={index}>
                <a
                  className="dropdown-item"
                  href="#!"
                  onClick={() => handleClick(item, index)}
                >
                  {item}
                </a>
              </li>
            ))}
          </ul>
        )}
      </li>
    </div>
  );
};

export default CustomDropdown;
