import {
  MDBBtn,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBTextArea,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import ListaActosNew from "./ListaActosNew";
import axios from "axios";
import { Form } from "react-bootstrap";
import URLAPI from "../../helpers/Path";



const New = () => {
  const urlPhp = URLAPI+"guarda_acto.php";
  const ramasPhp = URLAPI+"lista_ramas.php";

  const [ramas, setRamas] = useState([])
  const [selectIdRama, setSelectIdRama] = useState(0);

  const [dataAj, setDataAj] = useState({
    rama: 0,
    nombre: "",
    requisitos: "",
  });

  const [dataVariable, setDataVariable] = useState([]);

  const [inputVariable, setInputVariable] = useState("");

  const handleInputChange = (e, type) => {
    switch (type) {
      case "legalAct":
        setDataAj({ ...dataAj, nombre: e.target.value });
        break;
      case "requirements":
        setDataAj({ ...dataAj, requisitos: e.target.value });
        break;
      default:
    }
  };

  const handleInputChangeIdRama = (e) => {
    const rama = parseInt(e.target.value)
    setSelectIdRama(rama)
  }
  

  // Items de actos juridicos variables
  const handleSubmitVariable = async () => {
    if (inputVariable.trim() === "") {
      return;
    }
    await setDataVariable((prevVariables) => [...prevVariables, inputVariable]);
    setInputVariable("");
  };

  const handleSubmitDataAj = async (e) => {
    const data = {
      id_rama: selectIdRama,
      nombre: dataAj.nombre,
      requisitos: dataAj.requisitos,
      variantes: dataVariable,
    };
    // console.log(data)
    try {
      const res = await axios.post(urlPhp, data);
      window.location.reload();
      
    } catch (error) {
      console.error("error al crear acto juridico", error);
    }

    setDataVariable([]);
    setSelectIdRama(0)
    setDataAj({
      rama: 0,
      nombre: "",
      requisitos: "",
    });
  };

  useEffect(() => {
    const getRamas = async() => {
      try {
        const res = await axios.get(ramasPhp) 
        setRamas(res.data)
      } catch (error) {
        console.error('No se hizo la conexion', error);
      }
    }
    getRamas()
  }, [])

  
  return (
    <>
      <hr />
      <div className="container">
        <h5 className="mt-3">Creación de nuevo acto jurídico</h5>
        <p>
          Seleccionar la rama e ingresar el nombre del acto juridico y sus respectivas
          variantes, en caso de no tener variantes dejar el campo en blanco
        </p>
        <div className="mt-4 ">
          <MDBRow>
            <MDBCol>
            <Form.Select
              className="form-select"
              onChange={handleInputChangeIdRama}
              value={selectIdRama}
              
            >
              <option value='' key={0}>Seleccionar rama</option>
              {ramas.map( (ramas) => (
                <option key={ramas.id_rama} value={ramas.id_rama}>{ramas.nombre}</option>
              ))}

            </Form.Select>
            </MDBCol>
            <MDBCol>
              <MDBInput
                label="Ingresar nombre del Acto Jurídico"
                type="text"
                value={dataAj.nombre}
                onChange={(e) => handleInputChange(e, "legalAct")}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <MDBTextArea
                className="mt-3"
                label="Ingresar requisitos"
                type="text"
                rows={7}
                value={dataAj.requisitos}
                onChange={(e) => handleInputChange(e, "requirements")}
              />
            </MDBCol>
          </MDBRow>
          {/* Item de variante de Acto Juridico */}
          <MDBRow>
            <MDBCol className="mt-3 me-1">
              <p>En caso que la VARIANTE del Acto Juridico tenga opciones por favor escribir: "Nombre de variante - opción" Ej: </p>
              <p>CONSTRUCCIÓN HABITACIONAL - DOMINIO PLENO (NP Y UV)</p>
              <p>CONSTRUCCIÓN HABITACIONAL - NUDA PROPIEDAD</p>
              <p >
                Presionar el botón de '+' para agregar la variante del Acto
                Jurídico
              </p>
            </MDBCol>
          </MDBRow>
          {/* Input para agregar variantes */}
          <MDBRow>
            <MDBCol size={11}>
              <MDBInput
                label="Ingresar el nombre del Acto Jurídico Variante"
                type="text"
                value={inputVariable}
                onChange={(e) => setInputVariable(e.target.value)}
              />
            </MDBCol>
            <MDBCol>
              <MDBBtn  onClick={handleSubmitVariable}>
                <MDBIcon fab icon="plus" size="md" />
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          {/* Mostrar variantes con opción de mostrarlas */}
          <MDBRow className="mt-3">
            <MDBCol>
              <ListaActosNew
                variablesAJ={dataVariable}
                onChangeDataVariable={setDataVariable}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow className="mt-3 ">
            <MDBCol className="justify-content-center">
              <div className="d-grid gap-2 col-5 mx-auto">
                <MDBBtn color="warning" onClick={handleSubmitDataAj}>
                  REGISTRAR ACTO
                </MDBBtn>
              </div>
            </MDBCol>
          </MDBRow>
        </div>
      </div>
    </>
  );
};

export default New;
