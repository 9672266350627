import axios from "axios";
import { MDBInput } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import FormQua from "./FormQua";
import { useParams } from "react-router-dom";
import URLAPI from "../../helpers/Path";

const New = () => {
  // States

  // Input Titular
  const [nameTitular, setNameTitular] = useState("");
  // Listas
  const [listRamas, setListRamas] = useState([]);
  const [listActos, setListActos] = useState([]);
  const [listVariantes, setListVariantes] = useState([]);
  const [listOpciones, setListOpciones] = useState([]);

  // Selects
  const [selectedRama, setSelectedRama] = useState(0);
  const [selectedActo, setSelectedActo] = useState(0);
  const [selectedVariante, setSelectedVarainte] = useState(0);
  const [selectedOpcion, setSelectedOpcion] = useState(0);
  const [selectedIdVariante, setSelectedIdVariante] = useState(0);

  // URLS PHP
  const ramasPhp = URLAPI+"lista_ramas.php";
  const getActosPhp = URLAPI+"lista_actos.php";
  const getVariantsPhp = URLAPI+"lista_variantes.php";
  const getOptions = URLAPI+"lista_opciones.php";
  const getTaxes = URLAPI+"lista_impuestos.php";

  // Validaciones Selects
  const [isSelectActo, setIsSelectActo] = useState(false);
  const [isSelectVariante, setIsSelectVariante] = useState(false);
  const [isSelectOpcion, setIsSelecOpcion] = useState(false);

  const [resImpuesto, setResImpuesto] = useState(undefined);

  // Obtenemos ID de usuario mediante URL
  const {id_user} = useParams()

  // OnChanges
  const handleOnChangeInputTitular = (e) => {
    const input = e.target.value;
    setNameTitular(input);
  };

  // Ramas
  useEffect(() => {
    const getRamas = async () => {
      try {
        const res = await axios.get(ramasPhp);
        setListRamas(res.data);
      } catch (error) {
        console.error("Error al obtener Ramas", error);
      }
    };
    getRamas();
  }, []);

  const handleChangeSelectRama = async (e) => {
    setSelectedActo(0);
    setSelectedIdVariante(0);
    setSelectedVarainte(0);
    setSelectedOpcion(0);
    setIsSelectVariante(false);
    setIsSelecOpcion(false);
    setResImpuesto(undefined);

    const id_rama = parseInt(e.target.value);
    setSelectedRama(id_rama);
    if (id_rama !== 0) {
      setIsSelectActo(true);
      try {
        const res = await axios.post(getActosPhp, { data: id_rama });
        setListActos(res.data);
      } catch (error) {
        console.error("Error al obtener actos juridicos", error);
      }
    } else {
      setIsSelectActo(false);
      setIsSelectVariante(false);
    }
  };

  // Actos Juridicos
  const handleChangeSeletActos = async (e) => {
    const id_acto = parseInt(e.target.value);
    setSelectedIdVariante(0);
    setSelectedOpcion(0);
    setSelectedVarainte(0);
    setIsSelecOpcion(false);
    setSelectedActo(id_acto);
    setResImpuesto(undefined);
    if (id_acto !== 0) {
      setIsSelectVariante(true);
      try {
        const res = await axios.post(getVariantsPhp, { data: id_acto });
        const arrayVariantes = res.data;
        setListVariantes(arrayVariantes);
        // Validaciónes si hay actos variantes
        if (arrayVariantes.length === 0) {
          const data = {
            id_acto: id_acto,
            id_variante: 0,
          };
          const resImp = await axios.post(getTaxes, data);
          setResImpuesto(parseInt(resImp.data));
          setIsSelectVariante(false);
          setIsSelecOpcion(false);
        }
      } catch (error) {
        console.error("Error al obtener variantes", error);
      }
    } else {
      setIsSelectVariante(false);
    }
  };

  // Variantes
  const handleChangeSelectVariantes = async (e) => {
    const id_variante = parseInt(e.target.value);
    setSelectedOpcion(0);
    setSelectedVarainte(id_variante);
    setSelectedIdVariante(id_variante);
    setResImpuesto(undefined);
    if (id_variante !== 0) {
      setIsSelecOpcion(true);
      const data = {
        id_acto: selectedActo,
        id_variante: id_variante,
      };
      try {
        const res = await axios.post(getOptions, data);
        const arrayOpciones = res.data;
        setListOpciones(arrayOpciones);
        if (arrayOpciones.length === 0) {
          setIsSelecOpcion(false);
          // Obtener impuestos
          const resImp = await axios.post(getTaxes, data);
          setResImpuesto(parseInt(resImp.data));
        }
      } catch (error) {
        console.error("Error al obtener Opciones", error);
      }
    } else {
      setIsSelecOpcion(false);
    }
  };

  // Opciones
  const handleChangeSelectOpcion = async (e) => {
    const id_varianteOpcion = parseInt(e.target.value);
    setResImpuesto(undefined);
    if (id_varianteOpcion !== 0) {
      setSelectedIdVariante(id_varianteOpcion);
      // Obtener Impuestos
      const data = {
        id_acto: selectedActo,
        id_variante: selectedIdVariante,
      };
      const resImp = await axios.post(getTaxes, data);
      setResImpuesto(parseInt(resImp.data));
    }
    setSelectedOpcion(id_varianteOpcion);
  };

  return (
    <>
      <hr />
      <h5>Creación de una cotización</h5>
      <p>Llena el siguiente formulario para dar de alta una nueva cotización</p>
      <div className="container mt-3">
        <MDBInput
          label="Titular"
          onChange={handleOnChangeInputTitular}
          value={nameTitular}
          placeholder="Apellido Paterno   Apellido Materno   Nombre"
        />
      </div>
      {/* Select Ramas */}
      <div className="mt-3 container">
        <Form.Select
          className="form-select"
          onChange={handleChangeSelectRama}
          value={selectedRama}
        >
          <option value={0} key={0}>
            Seleccionar una rama
          </option>
          {listRamas.map((element) => (
            <option value={element.id_rama} key={element.id_rama}>
              {element.nombre}
            </option>
          ))}
        </Form.Select>

        {/* Actos Juridicos */}
        {isSelectActo && (
          <Form.Select
            className="form-select mt-3"
            value={selectedActo}
            onChange={handleChangeSeletActos}
          >
            <option value={0} key={0}>
              Seleccionar un acto
            </option>
            {listActos.map((element) => (
              <option value={element.id_acto} key={element.id_acto}>
                {element.nombre}
              </option>
            ))}
          </Form.Select>
        )}
        {/* Variantes */}
        {isSelectVariante && (
          <Form.Select
            className="form-select mt-3"
            value={selectedVariante}
            onChange={handleChangeSelectVariantes}
          >
            <option value={0} key={0}>
              Seleccionar una variante
            </option>
            {listVariantes.map((element) => (
              <option value={element.id_variante} key={element.id_variante}>
                {element.nombre}
              </option>
            ))}
          </Form.Select>
        )}
        {/* Opciones */}
        {isSelectOpcion && (
          <Form.Select
            className="form-select mt-3"
            value={selectedOpcion}
            onChange={handleChangeSelectOpcion}
          >
            <option value={0} key={0}>
              Seleccionar una opción
            </option>
            {listOpciones.map((element) => (
              <option value={element.id_variante} key={element.id_variante}>
                {element.nombre}
              </option>
            ))}
          </Form.Select>
        )}

        {/* Form */}

        {resImpuesto !== undefined && (
          <FormQua
            id_user={id_user}
            resImpuesto={resImpuesto}
            nombreTitular={nameTitular}
            selectRama={selectedRama}
            selectActo={selectedActo}
            selectIdVariante={selectedIdVariante}
          />
        )}
      </div>
    </>
  );
};

export default New;
