import axios from "axios";
import { MDBBtn, MDBCol, MDBInput, MDBRow, MDBSwitch } from "mdb-react-ui-kit";

import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import CostsAssignment from "./CostsAssignment";
import Taxes from "./Taxes";
import Alerts from "../Alerts";
import URLAPI from "../../helpers/Path";

const Assignments = () => {
  // States Actos Juridicos
  const [legalActs, setLegalActs] = useState([]);
  const [selectionLegalAct, setSelectionLegalAct] = useState(0);

  // States de Ramas
  const [ramas, setRamas] = useState([]);
  const [selectIdRama, setSelectIdRama] = useState(0);
  const [isSelectRama, setIsSelectRama] = useState(true);

  // States de vulnerable
  const [aVulnerable, setAVulnerable] = useState(0);
  const [isVulnerable, setIsVulnerable] = useState(false);
  const [umbral, setUmbral] = useState(0);

  // States de Variantes
  const [variants, setVariants] = useState([]); // Se guardan la lista de variantes para mostrar
  const [selectionVariant, setSelectionVariant] = useState(""); // Se guarda la variante seleccionada para ejecutar
  const [selectionIdVariant, setSelectionIdVariant] = useState(0);
  // States de costos
  const [costos, setCostos] = useState([]);

  // States de validaciones
  const [isSelect, setIsSelect] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [msg, setMsg] = useState("");

  // States de validaciones para opciones de variantes
  const [showSelectOption, setShowSelectOption] = useState(false);
  const [selectOption, setSelectOption] = useState(0);
  const [listOptions, setListOptions] = useState([]);
  const [isAlert, setIsAlert] = useState(false);

  const [taxes, setTaxes] = useState([]);

  // Variables
  const ramasPhp = URLAPI+"lista_ramas.php";
  const getActosPhp = URLAPI+"lista_actos.php";
  const getVariantsPhp = URLAPI+"lista_variantes.php";
  const getOptions = URLAPI+"lista_opciones.php";
  const saveData = URLAPI+"guarda_costos.php";

  // Acciones

  // onChange Select Rama
  const handleSelectRamaChange = async (e) => {
    const idRama = parseInt(e.target.value);
    if (idRama > 0) {
      try {
        const res = await axios.post(getActosPhp, { data: idRama });
        setLegalActs(res.data);
      } catch (error) {
        console.error("No se pudo mandar los datos", error);
      }
      setIsSelectRama(false);
      setMsg("");
    } else {
      setMsg("Seleccione una rama para asignar costos");
      setIsSelectRama(true);
    }
    setSelectIdRama(idRama);
  };

  // cuando se selecciona se le asigna el id al state y se manda a llamar la funcion para recibir la lista de las variantes
  const handleSelectChangeLegalAct = (e) => {
    const acto = parseInt(e.target.value);
    if (acto === 0) {
      setMsg("Seleccionar Acto Juridico para asignar costos");
    } else {
      setMsg("");
    }
    setSelectionLegalAct(acto);
  };

  // OnChange de Select de las variantes
  const handleSelectChangeVariant = async (e) => {
    const id_variant = parseInt(e.target.value);
    setSelectionIdVariant(id_variant);
    setSelectionVariant(id_variant);
    if (id_variant !== 0) {
      const data = {
        id_acto: selectionLegalAct,
        id_variante: id_variant,
      };
      try {
        const res = await axios.post(getOptions, data);
        setListOptions(res.data);
        // console.log(res.data)
      } catch (error) {
        console.error("No se pudo obtener las opciones");
      }
      setMsg("");
    } else {
      setMsg("Selecciona Variante");
      setShowSelectOption(false);
      setShowForm(false);
    }
  };

  // OnChange Select de las opciones
  const handleSelectChangeOptions = async (e) => {
    const opt = parseInt(e.target.value);

    if (opt !== 0) {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
    setSelectOption(opt);
    setSelectionIdVariant(opt);
  };

  // onChange Actividad Vulnerable
  const handleSwitchVulnerable = () => {
    setUmbral(0);
    setIsVulnerable(!isVulnerable);
    if (isVulnerable) {
      setAVulnerable(0);
    } else {
      setAVulnerable(1);
    }
  };

  // Submit Data
  const submitData = async () => {
    if (
      selectionLegalAct !== 0 &&
      selectIdRama !== 0 &&
      costos.length > 0 &&
      taxes.length > 0
    ) {
      const data = {
        id_rama: selectIdRama,
        id_acto: selectionLegalAct,
        id_variante: selectionIdVariant,
        vulnerable: aVulnerable,
        umbral: umbral,
        costos: costos,
        impuestos: taxes,
      };
      try {
        await axios.post(saveData, data);
        setIsAlert(true);
      } catch (error) {
        console.error("Error al guardar el acto", error);
      }
      setSelectionLegalAct(0);
      setSelectionVariant(0);
      setAVulnerable(0);
      setUmbral(0);
      setIsVulnerable(false);
      setSelectIdRama(0);
      setMsg("");
    } else {
      setMsg("Verifique los campos para subir información");
    }
  };

  // Effects

  // Obtener ramas y desplegarlos en un select
  useEffect(() => {
    const getBrenchs = async () => {
      try {
        const res = await axios.get(ramasPhp);
        setRamas(res.data);
      } catch (error) {
        console.error("Error en la conexión", error);
      }
    };
    getBrenchs();
  }, []);

  // Obtener lista de actos juridicos para desplegarlos en el select

  // Obtener lista de variantes de cada acto mandando le id de la variante
  useEffect(() => {
    const getVariantsList = async () => {
      if (selectionLegalAct !== 0) {
        try {
          const res = await axios.post(getVariantsPhp, {
            data: selectionLegalAct,
          });
          const vr = res.data;
          // console.log(vr)
          setVariants(vr);
          setIsSelect(true);
          if (vr.length === 0) {
            setShowForm(true);
            setIsSelect(false);
            setShowSelectOption(false);
            setSelectOption(0);
          } else {
            setMsg("Seleccionar Variante");
            setShowForm(false);
            setShowSelectOption(false);
          }
        } catch (error) {
          console.error("Error al obtener las variantes: ", error);
        }
      } else {
        setIsSelect(false);
        setShowForm(false);
      }
      setShowSelectOption(false);
      setSelectOption(0);
      setSelectionVariant(0);
    };
    getVariantsList();
  }, [selectionLegalAct]);

  // Use effect para las validaciones de la opciones y formulario
  useEffect(() => {
    if (listOptions.length !== 0) {
      setShowSelectOption(true);
    } else {
      setShowSelectOption(false);
      setShowForm(true);
    }
  }, [listOptions]);

  useEffect(() => {
    setAVulnerable(false);
    setSelectionLegalAct(0);
    setSelectionVariant(0);
    setShowForm(false);
    setShowSelectOption(false);
    setSelectOption(0);
  }, [selectIdRama]);

  // Mostrar formulario al momento de seleccionar la variante
  // useEffect(() => {
  //   if (selectionVariant !== 0) {
  //     setShowForm(true);
  //   } else {
  //     setShowForm(false);
  //   }
  // }, [selectionVariant]);

  return (
    <>
      {isAlert && (
        <Alerts
          isShow={isAlert}
          variant="success"
          head="AVISO"
          msg="Se han asignado correctamente los impuestos y honorarios"
          clicked={() => window.location.reload()}
          textButton="OK"
          variantButton="outline-success"
        />
      )}
      <hr />
      <div className="mt-3 container">
        <h5>Asignaciones de honorarios e impuestos</h5>
        <p>
          Seleccionar el acto jurídico y sus variantes respectivas para la
          asignación de honorarios e impuestos
        </p>
        <hr />

        {/* Select Actos Juridicos */}
        <p style={{ color: "red" }}>{msg}</p>
        <MDBRow>
          <MDBCol>
            <Form.Select
              className="form-select"
              onChange={handleSelectRamaChange}
              value={selectIdRama}
            >
              <option value={0} key={0}>
                Selecciona una rama
              </option>
              {ramas.map((ramas) => (
                <option value={ramas.id_rama} key={ramas.id_rama}>
                  {ramas.nombre}
                </option>
              ))}
            </Form.Select>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-3">
          <MDBCol>
            <Form.Select
              className="form-select"
              onChange={handleSelectChangeLegalAct}
              value={selectionLegalAct}
              disabled={isSelectRama}
            >
              <option value={0} key={0}>
                Selecciona acto jurídico
              </option>
              {legalActs.map((legalActs) => (
                <option key={legalActs.id_acto} value={legalActs.id_acto}>
                  {legalActs.nombre}
                </option>
              ))}
            </Form.Select>
          </MDBCol>

          {/* Select de variantes de Actos juridicos */}
          <MDBCol>
            <Form.Select
              className="form-select"
              onChange={handleSelectChangeVariant}
              value={selectionVariant}
              disabled={!isSelect}
            >
              <option value={0} key={0}>
                Selecciona variante
              </option>
              {variants.map((items) => (
                <option key={items.id_variante} value={items.id_variante}>
                  {items.nombre}
                </option>
              ))}
            </Form.Select>
          </MDBCol>
        </MDBRow>

        {/* Select de opciones de acto juridico */}
        {showSelectOption && (
          <MDBRow className="mt-3">
            <MDBCol>
              <Form.Select
                className="form-select"
                onChange={handleSelectChangeOptions}
                value={selectOption}
              >
                <option value={0} key={0}>
                  Selecciona opción
                </option>
                {listOptions.map((elemnt) => (
                  <option key={elemnt.id_variante} value={elemnt.id_variante}>
                    {elemnt.nombre}
                  </option>
                ))}
              </Form.Select>
            </MDBCol>
          </MDBRow>
        )}
        {showForm && (
          <>
            <MDBRow className="mt-3">
              <MDBCol>
                <MDBSwitch
                  id="flexSwitchCheckDefault"
                  label="¿Es actividad vulnerable?"
                  size="lg"
                  value={isVulnerable}
                  onChange={handleSwitchVulnerable}
                />
              </MDBCol>
              {isVulnerable && (
                <MDBCol className="d-flex justify-contend-start">
                  <MDBInput
                    label="Ingrese el umbral en UMAs"
                    type="text"
                    value={umbral}
                    onChange={(e) => setUmbral(e.target.value)}
                  />
                </MDBCol>
              )}
            </MDBRow>
            {/* Asignacion de costos */}
            <hr />
            <div className="mt-4">
              <h5 className="mb-4">Asignación de honorarios</h5>
              <CostsAssignment onChangeDataCosts={setCostos} />
            </div>

            {/* Impuestos view */}
            <div className="mt-4 mb-4">
              <h5>Asignación de impuestos</h5>
              <p>Seleccionar los impuestos que corresponden al acto jurídico</p>
              <hr />
              <Taxes addTaxes={setTaxes} />
            </div>

            <MDBRow className="mt-4 ">
              <MDBCol className="justify-content-center ">
                <div className="d-grid gap-2 col-4 mx-auto">
                  <MDBBtn onClick={submitData} color="success">
                    Subir Costos
                  </MDBBtn>
                </div>
              </MDBCol>
            </MDBRow>
          </>
        )}
      </div>
    </>
  );
};

export default Assignments;
