import axios from "axios";
import { MDBCol, MDBInput, MDBRow, MDBSwitch } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import URLAPI from "../../helpers/Path";

const Taxes = ({ addTaxes }) => {
  const getListTaxes = URLAPI+"listado_impuestos.php";

  // State de list y select
  const [listTaxes, setListTaxes] = useState([]);
  const [listUpdated, setListUpdated] = useState([]);

  // Se asigan el isChecked en la misma lista y se acutalicen conforme el evento de switch
  const onChangeSwitch = async (id) => {
    const updatedItems = listTaxes.map((item) =>
      item.id_impuesto === id ? { ...item, isChecked: !item.isChecked } : item
    );

    // Filtrar elementos con el checked
    await setListTaxes(updatedItems);
  };

  const handleOnChangeInput =  (e, id) => {

    setListTaxes((prevList) => {
      return prevList.map((item) =>
        item.tipo_impuesto === "1" && item.id_impuesto === id
          ? { ...item, monto: e.target.value }
          : item
      );
    });
  };

  useEffect(() => {
    const getShowList = async () => {
      try {
        const res = await axios.get(getListTaxes);
        console.log(res.data)
        const elementsWithChecked = res.data.map((element) => ({
          ...element,
          isChecked: false,
          monto: '0'
        }));
        setListTaxes(elementsWithChecked);
      } catch (error) {
        console.error("Error en la conexión de base de datos");
      }
    };
    getShowList();
  }, []);

  // Se filtra la lista que solo tenga isChecked = true y despues se hace el recorrido con .map para asignar los id's
  useEffect(() => {
    const updatedListChecked = listTaxes
      .filter((element) => element.isChecked === true)
      .map((element) => element);
    setListUpdated(updatedListChecked);
    // console.log(updatedListChecked);
  }, [listTaxes]);

  useEffect(() => {
    // console.log(listUpdated)
    addTaxes(listUpdated);
  }, [listUpdated]);

  // Funcionalidad de check box

  return (
    <>
      {listTaxes.map((item, index) => (
        <MDBRow key={index} className="m-1">
          <MDBCol size={7} className="mb-2">
            <MDBSwitch
              label={item.nombre}
              size="lg"
              value={item.isChecked}
              onChange={() => onChangeSwitch(item.id_impuesto)}
            />
          </MDBCol>
          {/* <MDBCol className="m-2" >{item.nombre}</MDBCol> */}
          {item.tipo_impuesto === "1" && (
            <MDBCol>
              <MDBInput
                type="number"
                label={`Agregar costo de ${item.nombre}`}
                disabled={!item.isChecked}
                value={item.monto}
                onChange={(e) => handleOnChangeInput(e, item.id_impuesto)}
              />
            </MDBCol>
          )}
        </MDBRow>
      ))}
    </>
  );
};

export default Taxes;
