import React from "react";

// Imports Users
import NewU from "../components/users/New";
import EditU from "../components/users/Edit";
import DeleteU from "../components/users/Delete";

// Imports legal acts
import NewAJ from "../components/legalActs/New";
import Assignments from "../components/legalActs/Assignments";
// import EditAJ from "../components/legalActs/Edit";
// import DeleteAJ from "../components/legalActs/Delete";

// Imports Taxes
// import NewTx from "../components/taxes/New";
// import EditTx from "../components/taxes/Edit";
// import DeleteTx from "../components/taxes/Delete";

// Imports quotations
import NewQ from "../components/quotation/New";
import ConsultQ from "../components/quotation/Consult";
import Consult from "../components/legalActs/Consult";



export const handleComponent = (dropdownName, indexItem) => {
  // Usuarios
  if (dropdownName === "Usuarios") {
    switch (indexItem) {
      case 0:
        return <NewU />;
      case 1:
        return <EditU />;
      case 2:
        return <DeleteU />;
      default:
        console.log("Ningun item seleccionado");
        break;
    }
  } else if (dropdownName === "Actos jurídicos") {
    switch (indexItem) {
      case 0:
        return <NewAJ />;
      case 1:
        return <Assignments />
      case 2:
        return <Consult />;
      // case 2:
      //   return <DeleteAJ />;
      default:
        console.log("Ningun item seleccionado");
        break;
    }
  // } else if (dropdownName === "Impuestos") {
  //   switch (indexItem) {
  //     case 0:
  //       return <NewTx />;
  //     case 1:
  //       return <EditTx />;
  //     case 2:
  //       return <DeleteTx />;
  //     default:
  //       console.log("Ningun item seleccionado");
  //       break;
  //   }
  } else if (dropdownName === "Cotizador") {
    switch (indexItem) {
      case 0:
        return <NewQ />;
      case 1:
        return <ConsultQ />;
      default:
        console.log("Ningun item seleccionado");
        break;
    }
  }
};
