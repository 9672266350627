import { MDBIcon } from "mdb-react-ui-kit";
import React, { useState } from "react";
import "../styles/admin.css";
import CustomDropdown from "../components/CustomDropdown";
import { useNavigate } from "react-router-dom";
import DefaultMenuScreen from "../components/DefaultMenuScreen";
import { handleComponent } from "../helpers/Forms";

const Admin = () => {
  const navigate = useNavigate();
  const [selectItem, setSelectItem] = useState(null);

  const handleSelectItem = (dropdownName, selectItem, indexItem) => {
    setSelectItem({ dropdownName, selectItem, indexItem });
  };
  const handleExit = () => {
    navigate("/");
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="bg-light  col-auto col-md-3 min-vh-100 " expand="lg">
            <a className="text-decoration-none d-flex align-itemcenter">
              <span className="ms-1 fd-4 mt-4 p-3">
                <h2 className="title-text">Administrador</h2>
              </span>
            </a>
            <ul className=" nav nav-underline flex-column m-3">
              <CustomDropdown
                title="Actos jurídicos"
                items={["Nuevo", "Asignaciones", "Consultar"]}
                iconMDB="balance-scale"
                onSelectOpt={handleSelectItem}
              />
              <CustomDropdown
                title="Cotizador"
                items={["Nuevo", "Consultar/Cancelar"]}
                iconMDB="hand-holding-usd"
                onSelectOpt={handleSelectItem}
              />
              <CustomDropdown
                title="Usuarios"
                items={["Nuevo", "Editar", "Eliminar"]}
                iconMDB="user-alt"
                onSelectOpt={handleSelectItem}
              />

              <li className="nav-item align-items-end justify-content-end">
                <button
                  href=""
                  className="nav-link active w-100 text-start fs-5"
                  onClick={handleExit}
                >
                  <MDBIcon fas icon="sign-out-alt" className="m-2" />
                  <span>Salir</span>
                </button>
              </li>
            </ul>
          </div>
          {/* Items o pantalla default */}
          <div className="col-auto col-md-9 min-vh-100 mt-4">
            {selectItem ? (
              <div className="m-5">
                <h3>{selectItem.dropdownName}</h3>
                {handleComponent(selectItem.dropdownName, selectItem.indexItem)}
                {/* Aqui se despliega componente de formularios de cada opcion */}
              </div>
            ) : (
              <DefaultMenuScreen />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
